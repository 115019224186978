import React from 'react';
import ReactDOM from 'react-dom';

class GetDigit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { solution: " " };
    this.state = { title: " " };
  }
  setTitle = (value) => {
    this.setState({title: value});
  }
  convertNumber = (n) => {
    var string = n.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = 'and';

		/* Remove spaces and commas */
		string = string.replace(/[, ]/g,"");

		/* Is number zero? */
		if( parseInt( string ) === 0 ) {
			return 'zero';
		}
		
		/* Array of units as words */
		units = [ '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen' ];
		
		/* Array of tens as words */
		tens = [ '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety' ];
		
		/* Array of scales as words */
		scales = [ '', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion' ];
		
		/* Split user arguemnt into 3 digit chunks from right to left */
		start = string.length;
		chunks = [];
		while( start > 0 ) {
			end = start;
			chunks.push( string.slice( ( start = Math.max( 0, start - 3 ) ), end ) );
		}
		
		/* Check if function has enough scale words to be able to stringify the user argument */
		chunksLen = chunks.length;
		if( chunksLen > scales.length ) {
			return '';
		}
		
		/* Stringify each integer in each chunk */
		words = [];
		for( i = 0; i < chunksLen; i++ ) {
			
			chunk = parseInt( chunks[i] );
			
			if( chunk ) {
				
				/* Split chunk into array of individual integers */
				ints = chunks[i].split( '' ).reverse().map( parseFloat );
			
				/* If tens integer is 1, i.e. 10, then add 10 to units integer */
				if( ints[1] === 1 ) {
					ints[0] += 10;
				}
				
				/* Add scale word if chunk is not zero and array item exists */
				if( ( word = scales[i] ) ) {
					words.push( word );
				}
				
				/* Add unit word if array item exists */
				if( ( word = units[ ints[0] ] ) ) {
					words.push( word );
				}
				
				/* Add tens word if array item exists */
				if( ( word = tens[ ints[1] ] ) ) {
					words.push( word );
				}
				
				/* Add 'and' string after units or tens integer if: */
				if( ints[0] || ints[1] ) {
					
					/* Chunk has a hundreds integer or chunk is the first of multiple chunks */
					if( ints[2] || ! i && chunksLen ) {
            if(this.state.title > 99){
						  words.push( and );
            }
					}
				
				}
				
				/* Add hundreds word if array item exists */
				if( ( word = units[ ints[2] ] ) ) {
					words.push( word + ' hundred' );
				}
				
			}
			
		}
		
		const result = words.reverse().join( ' ' );
    this.setState({solution: result});

  }
  
  render() {

    return (
      <div>
        <h1>Numbers to Words Converter</h1>
        <form>
          <p><input type='digits' placeholder= 'Numbers only' onChange={(event) => this.setTitle(event.target.value)} /></p>
          <p><button
          type="button"
          onClick={() => this.convertNumber(this.state.title)}
        >Convert</button></p>
        </form> 
        <h2>Answer: {this.state.solution}</h2>
      </div>
    )
  }
}

const element = <GetDigit/>;
ReactDOM.render(element, document.getElementById('root'));

